import { Title } from '@solidjs/meta';
import {
	Button,
	Errors,
	Field,
	Heading,
	Input,
	Label,
	Option,
	Select,
	TextField,
	TextLink,
	Container,
	Page,
	BreadcrumbItem,
	HorizontalRule,
	Link,
	Section,
	Callout,
	Picture,
} from '@troon/ui';
import { Formspree, formspreeAction } from '@troon/forms';
import { createSignal, For, Match, Show, Switch } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import { useUser } from '../../providers/user';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';
import type { RouteDefinition } from '@solidjs/router';

export default function ContactUsPage() {
	const [reason, setReason] = createSignal<string>('');
	const submission = useSubmission(action);
	const user = useUser();

	return (
		<>
			<Title>Contact Us | Troon</Title>

			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/digital/hero/contact.jpg`}
				crop="center"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
						<BreadcrumbItem href="/about/contact">Contact us</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1" class="text-center text-white">
					Contact Us
				</Heading>
			</Hero>

			<Container>
				<Page>
					<Grid>
						<GridFive>
							<Section>
								<Heading as="h2">Have a question?</Heading>
								<p>
									If you have any questions or need help with something, please feel free to reach out to us using the
									contact form.
								</p>

								<p>
									If you are a Troon Rewards member who did not receive Rewards points for a tee-time or merchandise
									purchase please click the button below.
								</p>

								<Button as={Link} appearance="secondary" href="/rewards/points-request" class="size-fit grow-0">
									Missing Points
								</Button>
							</Section>
						</GridFive>
						<GridSeven class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
							<Switch>
								<Match when={submission.result}>
									<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
										<Icon name="circle-check" class="text-8xl text-brand" />
										<Heading as="h1" size="h2">
											Message received!
										</Heading>
										<p class="text-lg">
											Thanks for contacting us! Our team is reviewing your request and we will get in touch with you
											shortly.
										</p>
									</div>
								</Match>
								<Match when>
									<Formspree action={action}>
										<Field name="Reason for contact" required>
											<Label>Reason for contact</Label>
											<Select
												onChange={(event) => {
													setReason(event.currentTarget.value);
												}}
											>
												<Option disabled selected class="hidden" />
												<Show when={import.meta.env.ENVIRONMENT !== 'production'}>
													<Option>DEV-ONLY Test submission</Option>
												</Show>
												<Option>Career/Employment</Option>
												<Option>Event Planning</Option>
												<Option>Estore/Sales</Option>
												<Option>Human Resources</Option>
												<Option>Management Services</Option>
												<Option>Payroll</Option>
												<Option>Troon App</Option>
												<Option>Troon Access/Troon Card</Option>
												<Option>Troon Events</Option>
												<Option>Troon Rewards</Option>
												<Option>Website Related</Option>
												<Option>Other</Option>
											</Select>
										</Field>

										<TextField name="Name" required>
											<Label>First and last name</Label>
											<Input
												autocomplete="name"
												value={user() ? `${user()?.me.firstName} ${user()?.me.lastName}` : undefined}
											/>
										</TextField>

										<div class="flex w-full justify-stretch gap-4">
											<TextField name="email" required class="grow">
												<Label>Email address</Label>
												<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
											</TextField>

											<TextField name="Phone number" class="grow">
												<Label>Phone number</Label>
												<Input autocomplete="tel" inputMode="tel" />
											</TextField>
										</div>

										<TextField name="Street address">
											<Label>Street address</Label>
											<Input autocomplete="street-address" />
										</TextField>

										<div class="flex w-full justify-stretch gap-4">
											<TextField name="City" class="grow">
												<Label>City</Label>
												<Input autocomplete="address-level2" />
											</TextField>
											<TextField name="State" class="grow">
												<Label>State</Label>
												<Input autocomplete="address-level1" />
											</TextField>
											<TextField name="Postal Code" class="grow">
												<Label>Postal Code</Label>
												<Input autocomplete="postal-code" />
											</TextField>
										</div>

										<Switch>
											<Match when={reason() === 'Event Planning'}>
												<>
													<TextField name="Date(s) of event" required>
														<Label>Date(s) of event</Label>
														<Input type="date" />
													</TextField>

													<TextField name="Number of guests">
														<Label>How many guests?</Label>
														<Input inputMode="numeric" type="number" />
													</TextField>

													<TextField name="Desired location(s) City and State">
														<Label>Desired location(s) City and State</Label>
														<Input multiline />
													</TextField>

													<TextField name="Facility you are hoping to host your event">
														<Label>Facility you are hoping to host your event</Label>
														<Input />
													</TextField>
												</>
											</Match>
											<Match when={reason() === 'Troon Card' || reason() === 'Troon Rewards'}>
												<TextField name="Troon Rewards/Card Number">
													<Label>Troon Rewards/Card Number</Label>
													<Input />
												</TextField>
											</Match>
										</Switch>

										<TextField name="Supplemental information" required>
											<Label>Supplemental information</Label>
											<Input multiline />
										</TextField>

										<Errors />
										<Button type="submit" class="w-fit">
											Submit
										</Button>
									</Formspree>
								</Match>
							</Switch>
						</GridSeven>
					</Grid>

					<HorizontalRule />

					<Section>
						<Heading as="h2">Our Locations</Heading>

						<div
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="relative flex min-h-[500px] flex-col items-start justify-end gap-4 overflow-hidden rounded p-4 text-white md:p-8"
						>
							<Picture
								src={`${getConfigValue('IMAGE_HOST')}/digital/about/headquarters.jpeg`}
								alt="Troon Headquarters at sunset in Scottsdale Arizona"
								sizes={[
									[480, 600],
									[960, 480],
								]}
								// eslint-disable-next-line tailwindcss/no-arbitrary-value
								class="absolute inset-0 -z-10 size-full object-cover brightness-[70%]"
							/>
							<LocationDetails {...headquarters} linkClass="text-white underline" />
						</div>

						<ul class="grid gap-4 md:grid-cols-4 md:gap-6 md:gap-y-8">
							<For each={locations}>
								{(location) => (
									<Callout component="li" itemscope itemtype="https://schema.org/Organization">
										<LocationDetails {...location} />
									</Callout>
								)}
							</For>
						</ul>
					</Section>
				</Page>
			</Container>
		</>
	);
}

function LocationDetails(props: Location & { linkClass?: string }) {
	return (
		<>
			<strong itemprop="name" class="text-xl">
				{props.name}
			</strong>
			<div class="flex flex-col" itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
				<Show when={props.streetAddress1}>{(part) => <span itemprop="streetAddress">{part()}</span>}</Show>
				<Show when={props.streetAddress2}>{(part) => <span>{part()}</span>}</Show>
				<Show when={props.locality}>
					<span itemprop="addressLocality">{props.locality}</span>
				</Show>

				<Show when={props.country}>{(part) => <span itemprop="addressCountry">{part()}</span>}</Show>
			</div>
			<p class="flex flex-col gap-px">
				<Show when={props.phone}>
					{(phone) => (
						<span>
							Phone:{' '}
							<TextLink itemprop="telephone" href={`tel:${phone()}`} class={props.linkClass}>
								{phone()}
							</TextLink>
						</span>
					)}
				</Show>
				<Show when={props.fax}>
					{(fax) => (
						<span>
							Fax: <span itemprop="faxNumber">{fax()}</span>
						</span>
					)}
				</Show>
			</p>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'contact-us');

type Location = {
	name: string;
	streetAddress1: string;
	streetAddress2?: string;
	locality?: string;
	country: string;
	phone?: string;
	fax?: string;
};

const headquarters: Location = {
	name: 'Troon – Scottsdale (Corporate Headquarters)',
	streetAddress1: '15044 N. Scottsdale Road, Suite 300',
	locality: 'Scottsdale, AZ, 85254',
	country: 'United States',
	phone: '480.606.1000',
	fax: '480.606.1010',
};

const locations: Array<Location> = [
	{
		name: 'Troon – Jupiter',
		streetAddress1: '1061 E Indiantown Rd., Suite 400',
		locality: 'Jupiter, FL, 33477',
		country: 'United States',
		phone: '561.708.5160',
	},
	{
		name: 'Troon – Irvine',
		streetAddress1: '17875 Von Karman Avenue, Suite 225',
		locality: 'Irvine, CA, 92614',
		country: 'United States',
		phone: '949.800.8180',
	},
	{
		name: 'Troon – Birmingham',
		streetAddress1: '1400 Urban Center Drive, Suite 300',
		locality: 'Birmingham, AL, 35242',
		country: 'United States',
		phone: '205.298.0001',
		fax: '205.970.0304',
	},
	{
		name: 'Troon – Seattle',
		streetAddress1: '2501 15th Ave West',
		locality: 'Seattle, WA, 98119',
		country: 'United States',
		phone: '206.285.2200',
	},
	{
		name: 'Troon – Chicago',
		streetAddress1: '4611 North Ravenswood, Suite 101',
		locality: 'Chicago, IL, 60640',
		country: 'United States',
	},
	{
		name: 'Troon – Jacksonville',
		streetAddress1: '320 N. First Street, Suite 606',
		locality: 'Jacksonville Beach, FL, 32250',
		country: 'United States',
		phone: '904.694.0100',
	},
	{
		name: 'Troon – New Braunfels',
		streetAddress1: '625 Mission Valley Rd.',
		locality: 'New Braunfels, TX, 78132',
		country: 'United States',
		phone: '830.625.5911',
	},
	{
		name: 'Troon – Boston',
		streetAddress1: '63 Chapel Street',
		locality: 'Newton, MA, 02458',
		country: 'United States',
		phone: '617.876.2100',
	},
	{
		name: 'Troon – Reston',
		streetAddress1: '12700 Sunrise Valley Drive, Suite 300',
		locality: 'Reston, VA, 20191',
		country: 'United States',
		phone: '703.761.1444',
	},
	{
		name: 'Troon – Dubai',
		streetAddress1: 'PO Box 502618',
		streetAddress2: 'Loft Building 1-C, Office 210',
		locality: 'Dubai Media City, Dubai',
		country: 'United Arab Emirates',
		phone: '971.4.367.1080',
	},
	{
		name: 'Troon – Switzerland',
		streetAddress1: 'Avenue Louis-Casai 18',
		streetAddress2: '1209 Geneva',
		country: 'Switzerland',
		phone: '0041.22.770.5050',
	},
	{
		name: 'Troon – Fort Myers (Icon South)',
		streetAddress1: '11691 Gateway Blvd, Suite 203',
		locality: 'Fort Myers, FL, 33913',
		country: 'United States',
		phone: '239.561.1444',
	},
	{
		name: 'Troon – Bradenton (Icon North)',
		streetAddress1: '5540 State Road 64 East, Suite 220',
		locality: 'Bradenton, FL, 34208',
		country: 'United States',
		phone: '941.747.7261',
	},
	{
		name: 'Troon – Orlando (Icon Central)',
		streetAddress1: '6675 Westwood Blvd, Suite 100',
		locality: 'Orlando, FL, 32821',
		country: 'United States',
	},
];

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
